var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "fixed inset-0 z-10",
    attrs: { tabindex: "-1" },
    on: {
      click: function ($event) {
        $event.preventDefault()
        $event.stopPropagation()
        return _vm.emitClose.apply(null, arguments)
      },
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
        )
          return null
        return _vm.emitClose.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }