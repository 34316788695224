<template>
  <div ref="container" class="absolute" :style="{ left: xPx, top: yPx }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Teleport',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    position: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
  },
  computed: {
    xPx() {
      return `${this.position.x.toString()}px`;
    },
    yPx() {
      return `${this.position.y.toString()}px`;
    },
  },
  watch: {
    position() {
      this.addElement();
    },
    visible(value) {
      if (!value) this.removeElement();
    },
  },
  mounted() {
    this.addElement();
  },
  beforeDestroy() {
    if (this.visible) this.removeElement();
  },
  methods: {
    addElement() {
      document.body.appendChild(this.$refs.container);
    },
    removeElement() {
      if (document.body.contains(this.$refs.container)) document.body.removeChild(this.$refs.container);
    },
  },
};
</script>
