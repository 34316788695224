<template>
  <div>
    <div class="relative text-center mx-auto h-full">
      <div data-testid="asterix-dropdown-button" class="h-full" @click.prevent.stop="toggleDropdown">
        <slot :state="{ visible: isDropdownVisible, disabled: disabled }" name="button">
          <button
            class="w-full h-full flex items-center focus:border-0 focus:outline-none focus:shadow-outline rounded justify-center relative"
            :class="(disabled ? 'cursor-not-allowed ' : 'cursor-pointer ') + buttonClasses"
            :disabled="disabled"
            @click="$emit('clicked')"
          >
            <p class="text-sm mr-1">
              <slot :selected="optionSelected"></slot>
            </p>
            <slot :state="{ visible: isDropdownVisible, disabled: disabled }" name="icon">
              <span id="dropdown-indicator" class="transition-100" :class="!isDropdownVisible ? 'rotate-180' : ''">
                <up-svg class="w-2 h-2" :class="disabled ? 'text-gray-400' : 'text-gray-700'" />
              </span>
            </slot>
          </button>
        </slot>
      </div>
      <teleport :visible="isDropdownVisible" :position="positionOptionsList">
        <dismissible-background v-if="isDropdownVisible" @close="toggleDropdown()" />
        <slot :options="options" name="list">
          <div
            v-if="isDropdownVisible"
            id="option-list"
            data-testid="asterix-dropdown-options"
            class="border-t absolute rounded-lg shadow-md transition-100 z-100 bg-gray-800 p-3 text-gray-300 min-w-full whitespace-no-wrap overflow-y-scroll max-h-220"
            :class="optionListClasses"
            @click.stop
          >
            <slot name="dropdown-header" />
            <div
              v-for="(option, index) in options"
              :key="index"
              class="text-left cursor-pointer"
              :class="{ 'mt-2': index !== 0 }"
              @click.prevent.stop="onOptionClick(index)"
            >
              <slot :option="{ option, optionSelected }" name="option">
                <p
                  v-if="option"
                  class="w-full px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full"
                  :class="getStateClass(option)"
                  v-text="option.name"
                ></p>
              </slot>
            </div>
          </div>
        </slot>
      </teleport>
    </div>
  </div>
</template>

<script>
import DismissibleBackground from '@/components/atoms/DismissibleBackground';
import Teleport from '@/components/organisms/shared/Teleport.vue';

const DROPDOWN_POSITION = {
  BOTTOM: 'bottom',
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  LEFT_BOTTOM: 'leftBottom',
  LEFT_TOP: 'leftTop',
};

export default {
  name: 'AsterixDropdown',
  components: {
    DismissibleBackground,
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
    Teleport,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    buttonClasses: {
      type: String,
      default: () => '',
    },
    optionListPosition: {
      type: String,
      default: () => DROPDOWN_POSITION.BOTTOM,
      validator(value) {
        return Object.values(DROPDOWN_POSITION).includes(value);
      },
    },
  },
  data: () => ({
    optionSelected: null,
    isDropdownVisible: false,
    positionOptionsList: { x: 0, y: 0 },
  }),
  computed: {
    optionListClasses() {
      return [this.isDropdownVisible ? 'block' : 'hidden', this.optionListPositionClass];
    },
    optionListPositionClass() {
      let position;
      switch (this.optionListPosition) {
        case DROPDOWN_POSITION.BOTTOM:
          position = 'options-bottom';
          break;

        case DROPDOWN_POSITION.TOP:
          position = 'options-top';
          break;

        case DROPDOWN_POSITION.RIGHT:
          position = 'options-right';
          break;

        case DROPDOWN_POSITION.LEFT:
        case DROPDOWN_POSITION.LEFT_BOTTOM:
          position = 'options-left-bottom';
          break;

        case DROPDOWN_POSITION.LEFT_TOP:
          position = 'options-left-top';
          break;
      }

      return position;
    },
  },
  watch: {
    options: 'setValue',
    disabled() {
      this.toggleDropdown(null, false);
    },
    value() {
      this.setValue();
    },
    isDropdownVisible() {
      this.$emit('visible', this.isDropdownVisible);
    },
  },
  beforeMount() {
    this.setValue();
  },
  created() {
    window.addEventListener('resize', () => {
      this.setPosition(this.target);
    });
  },
  destroyed() {
    window.removeEventListener('resize', () => {});
  },
  methods: {
    getStateClass(option) {
      return option && option.id === (this.optionSelected && this.optionSelected.id) ? 'font-bold' : '';
    },
    setValue() {
      this.optionSelected = this.options.find(opt => opt.id === this.value?.id);
    },
    toggleDropdown(event, forceState) {
      this.setPosition(event?.target);
      const nextState = this.disabled ? false : !this.isDropdownVisible;
      this.isDropdownVisible = forceState ?? nextState;
    },
    onOptionClick(index) {
      this.optionSelected = this.options[index];
      this.toggleDropdown();
      this.emitOptionChange();
    },
    emitOptionChange() {
      this.$emit('change', this.optionSelected);
    },
    setPosition(target) {
      if (!target) return;
      this.target = target;

      const positioning = target.getBoundingClientRect();

      switch (this.optionListPosition) {
        case DROPDOWN_POSITION.BOTTOM: {
          this.positionOptionsList = {
            x: window.scrollX + positioning.x + positioning.width,
            y: window.scrollY + positioning.y + positioning.height,
          };
          break;
        }
        case DROPDOWN_POSITION.TOP: {
          this.positionOptionsList = {
            x: window.scrollX + positioning.x + positioning.width,
            y: window.scrollY + positioning.y,
          };
          break;
        }
        case DROPDOWN_POSITION.RIGHT: {
          this.positionOptionsList = {
            x: window.scrollX + positioning.x + positioning.width,
            y: window.scrollY + positioning.y,
          };
          break;
        }
        case DROPDOWN_POSITION.LEFT:
        case DROPDOWN_POSITION.LEFT_BOTTOM: {
          this.positionOptionsList = {
            x: window.scrollX + positioning.x,
            y: window.scrollY + positioning.y,
          };
          break;
        }
        case DROPDOWN_POSITION.LEFT_TOP: {
          this.positionOptionsList = {
            x: window.scrollX + positioning.x,
            y: window.scrollY + positioning.y + positioning.height,
          };
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.max-h-220 {
  max-height: 220px;
}
.options-top {
  right: 0rem;
  bottom: 100%;
  margin-bottom: 3px;
}
.options-bottom {
  right: 0rem;
  top: 100%;
  margin-top: 3px;
}
.options-right {
  left: 100%;
  top: 0rem;
  margin-left: 5px;
}
.options-left-bottom {
  right: 100%;
  top: 0rem;
  margin-right: 5px;
}
.options-left-top {
  right: 100%;
  bottom: 0rem;
  margin-right: 5px;
}
::v-deep button > * {
  pointer-events: none;
}
</style>
