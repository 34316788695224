var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "relative text-center mx-auto h-full" },
      [
        _c(
          "div",
          {
            staticClass: "h-full",
            attrs: { "data-testid": "asterix-dropdown-button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.toggleDropdown.apply(null, arguments)
              },
            },
          },
          [
            _vm._t(
              "button",
              function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-full h-full flex items-center focus:border-0 focus:outline-none focus:shadow-outline rounded justify-center relative",
                      class:
                        (_vm.disabled
                          ? "cursor-not-allowed "
                          : "cursor-pointer ") + _vm.buttonClasses,
                      attrs: { disabled: _vm.disabled },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clicked")
                        },
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "text-sm mr-1" },
                        [
                          _vm._t("default", null, {
                            selected: _vm.optionSelected,
                          }),
                        ],
                        2
                      ),
                      _vm._t(
                        "icon",
                        function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "transition-100",
                                class: !_vm.isDropdownVisible
                                  ? "rotate-180"
                                  : "",
                                attrs: { id: "dropdown-indicator" },
                              },
                              [
                                _c("up-svg", {
                                  staticClass: "w-2 h-2",
                                  class: _vm.disabled
                                    ? "text-gray-400"
                                    : "text-gray-700",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        {
                          state: {
                            visible: _vm.isDropdownVisible,
                            disabled: _vm.disabled,
                          },
                        }
                      ),
                    ],
                    2
                  ),
                ]
              },
              {
                state: {
                  visible: _vm.isDropdownVisible,
                  disabled: _vm.disabled,
                },
              }
            ),
          ],
          2
        ),
        _c(
          "teleport",
          {
            attrs: {
              visible: _vm.isDropdownVisible,
              position: _vm.positionOptionsList,
            },
          },
          [
            _vm.isDropdownVisible
              ? _c("dismissible-background", {
                  on: {
                    close: function ($event) {
                      return _vm.toggleDropdown()
                    },
                  },
                })
              : _vm._e(),
            _vm._t(
              "list",
              function () {
                return [
                  _vm.isDropdownVisible
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "border-t absolute rounded-lg shadow-md transition-100 z-100 bg-gray-800 p-3 text-gray-300 min-w-full whitespace-no-wrap overflow-y-scroll max-h-220",
                          class: _vm.optionListClasses,
                          attrs: {
                            id: "option-list",
                            "data-testid": "asterix-dropdown-options",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _vm._t("dropdown-header"),
                          _vm._l(_vm.options, function (option, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "text-left cursor-pointer",
                                class: { "mt-2": index !== 0 },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.onOptionClick(index)
                                  },
                                },
                              },
                              [
                                _vm._t(
                                  "option",
                                  function () {
                                    return [
                                      option
                                        ? _c("p", {
                                            staticClass:
                                              "w-full px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full",
                                            class: _vm.getStateClass(option),
                                            domProps: {
                                              textContent: _vm._s(option.name),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                  {
                                    option: {
                                      option,
                                      optionSelected: _vm.optionSelected,
                                    },
                                  }
                                ),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              },
              { options: _vm.options }
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }