<template>
  <div class="fixed inset-0 z-10" tabindex="-1" @click.prevent.stop="emitClose" @keydown.esc="emitClose" />
</template>

<script>
export default {
  name: 'DismissibleBackground',
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
};
</script>
